import { MainController } from './main_controller';
import { convertColor } from '../shared/psbc';
export default class PaymentbornesuccessController extends MainController {
  connect() {
    this.element['controller'] = this
    convertColor($(this.element).data().color)
    this.remaining = 30
    this.launchCountDown()
  }

  back() {
    Turbolinks.visit('/shops/splash_screen')
  }

  launchCountDown() {
    this.interval = setInterval(() => {
      this.remaining -= 1
      if(this.remaining == 0 && $('#payment-borne-success').length) {
        Turbolinks.visit('/shops/splash_screen')  
      }
    }, 1000)    
  }

  resetCountDown() {
    this.remaining = 30
  }


}