import { Controller } from 'stimulus';
export default class SigninborneController extends Controller {

  connect() {
    this.shopname = 'olousouzian-test-484'
  }

  shopnameChanged(e) {
    this.shopname = $(e.currentTarget).val()
    $(e.currentTarget).toggleClass('is-valid', $(e.currentTarget).val().length > 0)
    $(e.currentTarget).toggleClass('is-invalid', $(e.currentTarget).val().length == 0)
  }

  passwordChanged(e) {
    this.password = $(e.currentTarget).val()
    $(e.currentTarget).toggleClass('is-valid', $(e.currentTarget).val().length > 0)
    $(e.currentTarget).toggleClass('is-invalid', $(e.currentTarget).val().length == 0)    
  }

  submit() {
    $('#invalid-sign-in-response').hide()
    if(this.shopname.length && this.password.length) {
      grecaptcha.ready(() => {
        grecaptcha.execute($(this.element).data().recaptcha_key, {action: 'submit'}).then((token) => {
          $.ajax({
            method: 'POST',
            dataType: 'JSON',
            url: '/shops/sign_in',
            data: { 
              username: this.shopname,
              password: this.password,
              recaptchaToken: token
            }
          }).done((data) => {
            if(data.code == 200) {
              try {
                app.shopInit(JSON.stringify({shopName: data.name, shopId: data.shopId}));
              } catch (e) {
                console.log(e)
              }    
              Turbolinks.visit('/shops/splash_screen')
            } else {
              $('#invalid-sign-in-response').show()
              $('#invalid-sign-in-response').text(data.message)
            }
          })
        })
      })    
    }
  }
}